<template>
  <v-container fluid>
    <div
      id="judgeplan"
      style="overflow:scroll;background:white;"
    >
    <table>
      <thead style="background-color:white;">
      <tr>
        <th style="position:relative;z-index:10;"><!--v-btn text fab small @click="fullscreen()">
          <v-icon v-if="!isFullscreen">far fa-expand</v-icon>
          <v-icon v-else>far fa-compress</v-icon>
        </v-btn--></th>
        <template
          v-for="c in clubs"
        >
          <th
            v-for="(j,i) in c.judges"
            :key="`judge-${c.club._id}-${j.person._id}-${i}`"
          >
            <div>
              <span :style="{color:j.person.familyName === 'ZZZ' ? 'grey' : 'black'}">{{ j | person }}</span>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  fab
                  x-small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon v-if="j.comment">far fa-circle-info</v-icon>
                </v-btn>
              </template>
              <span>{{ j.comment }}</span>
            </v-tooltip>
          </th>
        </template>
      </tr>
      <tr>
        <th style="position:relative;z-index:10;text-align:right;">(Grün = Ligafinale)</th>
        <template
          v-for="c in clubs"
        >
          <th
            v-for="(j,i) in c.judges"
            :key="`judge-${c.club._id}-${j.person._id}-${i}`"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  fab
                  x-small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon :style="{color: j.available.find(a => a === '2025-05-10') || j.available.length === 0 ? 'rgba(0,192,0,0.8)' : 'black'}">far fa-circle-t</v-icon>
                </v-btn>
              </template>
              <span v-html="j.available.join('<br>')" />
            </v-tooltip>
          </th>
        </template>
      </tr>
      <tr>
        <th style="text-align:left;">
          <b>Legende:</b>
          <ul>
            <li style="background-color:rgba(255,255,0,0.3)">Verein hat Mannschaft in selber Liga</li>
            <li style="background-color:rgba(255,128,0,0.3)">Verein hat an selben Termin ein WK</li>
            <li style="background-color:rgba(255,0,0,0.3)">Wettkampf der eigenen Mannschaft</li>
            <li style="background-color:rgba(0,255,0,0.3)">Wunschtermin (oder nichts angegeben)</li>
          </ul>
        </th>
        <template
          v-for="c in clubs"
        >
          <th
            v-for="(j,i) in c.judges"
            :key="`club-${c.club._id}-${j.person._id}-${i}`"
            style="position:relative;"
            :id="`club-${c.club._id}-${j.person._id}-${i}`"
          >
            <div><span :style="{color:j.person.familyName === 'ZZZ' ? 'grey' : 'black'}">{{ c.club.name }}</span></div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  fab
                  x-small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>far fa-circle-info</v-icon>
                </v-btn>
              </template>
              <span>{{ c.leagues.map(l => l.shortName ).join(', ') }}</span>
            </v-tooltip>
          </th>
        </template>
      </tr>
      <tr>
        <th style="text-align:right;">max</th>
        <template
          v-for="c in clubs"
        >
          <th
            v-for="(j,i) in c.judges"
            :key="`max-${c.club._id}-${j.person._id}-${i}`"
          >
            {{ j.max ? j.max : '' }}
          </th>
        </template>
      </tr>
      <tr>
        <th style="text-align:right;">eingeteilt</th>
        <template
          v-for="c in clubs"
        >
          <th
            v-for="(j,i) in c.judges"
            :key="`eingeteilt-${c.club._id}-${j.person._id}-${i}`"
            :style="{'background-color': j.max !== 0 && j.count > j.max ? 'rgba(255,0,0,0.3)' : ''}"
          >
            {{ j.count }}
          </th>
        </template>
      </tr>
      <tr>
        <th style="text-align:right;">zu leistende Einsätze (Mannschaft)</th>
        <th
          v-for="c in clubs"
          :colspan="c.judges.length"
          :key="`todo-${c.club._id}`"
        >
          {{ c.todo }}
        </th>
      </tr>
      <tr>
        <th style="text-align:right;">eingeteilte Einsätze (Mannschaft)</th>
        <th
          v-for="c in clubs"
          :colspan="c.judges.length"
          :key="`todo-${c.club._id}`"
          :style="{'background-color': c.count !== c.todo ? 'rgba(255,0,0,0.3)' : 'rgba(0,192,0,0.3)'}"
        >
          {{ c.count }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="e in events"
        :key="e._id"
      >
        <th>
          <div>{{ e.startDate | dateformat('DD.MM.YY') }}</div>
          <div>{{ e.teams.filter(t => t.home).map(t => t.team.name ).join(', ') }}</div>
          <div>{{ e.teams.filter(t => !t.home).map(t => t.team.name ).join(', ') }}</div>
          <div>{{ e.league.shortName }}</div>
          <div><template v-if="e.ok && e.ok.person">{{ e.ok.person | person }}</template><br><template v-if="e.ok && e.ok.club"><i>{{ e.ok.club.name }}</i></template></div>
          <div><template v-if="e.neutral && e.neutral.person">{{ e.neutral.person | person }}</template><br><template v-if="e.neutral && e.neutral.club"><i>{{ e.neutral.club.name }}</i></template></div>
        </th>
        <template
          v-for="c in clubs"
        >
          <td
            v-for="(j,i) in c.judges"
            :key="`club-${c.club._id}-${j.person._id}-${i}`"
            :class="status(e, c, j)"
            @click="einteilen(e, c, j)"
          >
            <div>
              <div class="wish"></div>
              <div class="ok">OK</div>
              <div class="neutral">N</div>
              &nbsp;
            </div>
          </td>
        </template>
      </tr>
      </tbody>
    </table>
    </div>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { useGraphQL } from '@/plugins/graphql'

const querywk = `
  _id
  startDate
  teams {
    team { _id name club { _id } }
    home
    neutralwaiver
  }
  judges {
    person { _id familyName givenName }
    club { _id name }
    type
  }
`

const query = `
  _id
  ... on StbM2021 {
    leagues {
      _id
      name
      shortName
      order
      teams {
        team {
          _id name
          club { _id name }
        }
        judges {
          person { _id familyName givenName }
          available
          comment
          max
        }
      }
      events {
        ${querywk}
      }
    }
  }
`

export default {
  name: 'judgeplan',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    Event: {},
    isFullscreen: false
  }),

  computed: {
    clubs () {
      const c = Object.values(this.Event.leagues?.reduce((acc, curr) => {
        curr.teams.forEach(t => {
          if (!acc[t.team.club._id]) {
            acc[t.team.club._id] = {
              club: t.team.club,
              todo: 0,
              leagues: [],
              teams: [],
              judges: [
                {
                  person: { _id: 'club1', familyName: 'ZZZ', givenName: 'Kari 1: OK' },
                  available: [],
                  comment: '',
                  max: 0,
                  count: this.events.filter(e => e.judges?.find(j => j.person === null && j.club?._id === t.team.club._id && j.type === 'e1')).length
                },
                {
                  person: { _id: 'club2', familyName: 'ZZZ', givenName: 'Kari 2: Neutral' },
                  available: [],
                  comment: '',
                  max: 0,
                  count: this.events.filter(e => e.judges?.find(j => j.person === null && j.club?._id === t.team.club._id && j.type === 'e2')).length
                }
              ]
            }
          }

          acc[t.team.club._id].leagues.push({ id: curr._id, name: curr.name, shortName: curr.shortName, order: curr.order })
          acc[t.team.club._id].teams.push({ _id: t.team._id, name: t.team.name, league: curr.name })
          acc[t.team.club._id].judges.push(...(t.judges || []).map(j => ({
            ...j,
            count: this.events.filter(e => e.judges?.find(ej => ej.person?._id === j.person._id && ej.club?._id === t.team.club._id && (ej.type === 'e1' || ej.type === 'e2'))).length
          })))
        })
        return acc
      }, {}) || {}).sort((a, b) => a.club.name < b.club.name ? -1 : 1)

      c.forEach(c => {
        c.judges.sort((a, b) => {
          if (a.person.familyName < b.person.familyName) return -1
          if (a.person.familyName > b.person.familyName) return 1
          return a.person.givenName < b.person.givenName ? -1 : 1
        })
        c.todo = this.events.filter(e => e.teams.find(t => !!c.teams.find(t2 => t2._id === t.team._id))).length + c.teams.filter(t => t.league.indexOf('Kreisliga') >= -1).length
        c.count = this.allevents.reduce((acc, curr) => {
          if (curr.judges?.find(j => j.type === 'e1' && j.club?._id === c.club._id)) acc++
          if (curr.judges?.find(j => j.type === 'e2' && j.club?._id === c.club._id)) acc++
          return acc
        }, 0)// c.judges.reduce((acc, curr) => acc + curr.count, 0)
      })

      return c
    },
    allevents () {
      return this.Event.leagues?.reduce((acc, curr) => {
        acc.push(...(curr.events || []).map(e => ({
          ...e,
          teams: e.teams || [],
          league: {
            _id: curr._id,
            name: curr.name,
            shortName: curr.shortName,
            order: curr.order
          },
          ok: e.judges?.find(j => j.type === 'e1' && (j.person || j.club)) || null,
          neutral: e.judges?.find(j => j.type === 'e2' && (j.person || j.club)) || null
        })))
        return acc
      }, [])?.sort((a, b) => a.startDate < b.startDate ? -1 : 1) || []
    },
    events () {
      return this.allevents?.filter(e => {
        if (e.teams.length !== 2) return false
        if (e.league.name.indexOf('Kreisliga') !== -1) return false
        return !e.teams.reduce((acc, curr) => acc && curr.neutralwaiver, true)
      }) || []
    }
  },

  methods: {
    fullscreen () {
      this.$fullscreen.toggle(this.$el.querySelector('#judgeplan'), {
        wrap: false,
        callback: this.fullscreenChange
      })
    },
    fullscreenChange (fullscreen) {
      this.isFullscreen = fullscreen
    },
    status (event, club, person) {
      const sameclub = !!event.teams.find(t => t.team.club._id === club.club._id)
      const sameleague = !!club.leagues.find(l => l.id === event.league._id)
      const termine = this.allevents.filter(e => !!e.teams.find(t => t.team.club._id === club.club._id))
      const sametermin = !!termine.find(t => t.startDate.split(' ')[0] === event.startDate.split(' ')[0])
      const wish = (person.person.familyName === 'ZZZ' || person.available.length === 0 || !!person.available.find(t => t === event.startDate.split(' ')[0]))
      const ok = event.ok?.person?._id === person.person._id || (!event.ok?.person && event.ok?.club?._id === club.club._id && person.person._id === 'club1')
      const neutral = event.neutral?.person?._id === person.person._id || (!event.neutral?.person && event.neutral?.club?._id === club.club._id && person.person._id === 'club2')
      const einsatztermine = this.events.filter(e => !!e.judges?.find(j => j?.person?._id === person.person._id)) // || (person.person._id === 'club1' && j?.club?._id === club.club._id && j.type === 'e1') || (person.person._id === 'club2' && j?.club?._id === club.club._id && j.type === 'e2')))
      const einsatztermin = !!einsatztermine.find(e => e.startDate.split(' ')[0] === event.startDate.split(' ')[0])
      return {
        sameclub: sameclub || (einsatztermin && !ok && !neutral),
        sameleague,
        sametermin,
        wish,
        ok,
        neutral,
        einsatztermin
      }
    },
    async einteilen (event, club, person) {
      const status = this.status(event, club, person)

      const target1 = {
        _event: event._id,
        _person: undefined,
        _club: undefined,
        type: 'e1'
      }

      const target2 = {
        _event: event._id,
        _person: undefined,
        _club: undefined,
        type: 'e2'
      }

      if (event.ok && event.neutral && !status.ok && !status.neutral) {
        await this.$root.$children[0].$refs.confirm.open('Fehler', 'Bitte zuerst die eingeteilten Kampfrichter löschen', { width: 400 })
        return
      }

      if (status.ok) { // Person war bisher OK
        if (person.person._id === 'club1' || !!event.neutral) { // wenn Platzhalter 1 oder Neutral schon belegt -> löschen
          target1._person = null
          target1._club = null
        } else { // wenn richtige Person -> zu neutral verschieben
          target1._person = null
          target1._club = null
          target2._person = person.person._id
          target2._club = club.club._id
        }
      } else if (status.neutral) { // Person war bisher neutral -> löschen
        target2._person = null
        target2._club = null
      } else { // Person war noch nicht eingeteilt
        if (status.sameclub && status.einsatztermin) return
        if (status.sameclub && !status.einsatztermin && !(await this.$root.$children[0].$refs.confirm.open('Wirklich einteilen?', 'Dieser Kampfrichter ist für eine teilnehmende Mannschaft gemeldet', { width: 400 }))) return
        if (status.sametermin && !(await this.$root.$children[0].$refs.confirm.open('Wirklich einteilen?', 'Eine Heimmannschaft des Kampfrichters hat am selben Tag Wettkampf', { width: 400 }))) return
        if (status.sameleague && !(await this.$root.$children[0].$refs.confirm.open('Wirklich einteilen?', 'Dieser Kampfrichter ist in der selben Liga gemeldet', { width: 400 }))) return

        if (person.person._id === 'club1') {
          if (event.ok) {
            return await this.$root.$children[0].$refs.confirm.open('Fehler', 'Bitte zuerst den eingeteilten OK löschen', { width: 400 })
          }

          target1._person = null
          target1._club = club.club._id
        } else if (person.person._id === 'club2') { // wenn Platzhalter 2 oder bereits OK eingeteilt -> neutral
          if (event.neutral) {
            return await this.$root.$children[0].$refs.confirm.open('Fehler', 'Bitte zuerst den eingeteilten neutralen Kampfrichter löschen', { width: 400 })
          }
          target2._person = null
          target2._club = club.club._id
        } else if (!event.ok) {
          target1._person = person.person._id
          target1._club = club.club._id
        } else {
          target2._person = person.person._id
          target2._club = club.club._id
        }
      }

      if (target1._person !== undefined || target1._club !== undefined) {
        this.mutate({
          mutation: gql`
            mutation($id: UUID!, $type: StbM2021WkJudgeType!, $person: UUID, $club: UUID) {
              StbM2021WkJudgeAdd(id: $id, type: $type, person: $person, club: $club) { ${querywk} }
            }
          `,
          variables: {
            id: target1._event,
            type: target1.type,
            person: target1._person,
            club: target1._club
          }
        })
      }
      if (target2._person !== undefined || target2._club !== undefined) {
        this.mutate({
          mutation: gql`
            mutation($id: UUID!, $type: StbM2021WkJudgeType!, $person: UUID, $club: UUID) {
              StbM2021WkJudgeAdd(id: $id, type: $type, person: $person, club: $club) { ${querywk} }
            }
          `,
          variables: {
            id: target2._event,
            type: target2.type,
            person: target2._person,
            club: target2._club
          }
        })
      }
    }
  },

  apollo: {
    Event: {
      query: gql`
        query($id: UUID!) { Event(id: $id) { ${query} }}
      `,
      variables () {
        return {
          id: this.id
        }
      }
    }
  }
}
</script>

<style scoped>
table {
  border-collapse: collapse;
  position: relative;
}

thead {
  position: sticky;
  top: 0px;
  z-index: 20;
}

tbody {
  position: relative;
  z-index: 0;
}

tbody th {
  position: sticky;
  left: 0px;
  z-index: 10;
}

tbody th div {
  display: inline-block;
  position: relative;
}

tbody th div:nth-child(1) {
  width: 100px;
}
tbody th div:nth-child(2) {
  width: 190px;
}
tbody th div:nth-child(3) {
  width: 190px;
}
tbody th div:nth-child(4) {
  width: 50px;
}
tbody th div:nth-child(5), tbody th div:nth-child(6) {
  font-weight: normal;
  font-size: 80%;
  width: 160px;
}
tbody th div:nth-child(5) i, tbody th div:nth-child(6) i {
  color: grey;
  font-size: 90%;
}

tbody tr:nth-child(odd) > * {
  background-color: #EEEEEE;
}

tbody tr:nth-child(even) > * {
  background-color: #FFFFFF;
}

tbody tr:nth-child(odd) > *:nth-child(even) {
  background-color: #DDDDDD;
}

tbody tr:nth-child(even) > *:nth-child(even) {
  background-color: #EEEEEE;
}

tr > *:nth-child(even) {
  background-color: #EEEEEE;
}

th, td {
  padding: 0px;
  white-space: nowrap;
}

thead th > div {
  height: 180px;
  position: relative;
}

thead th > div > span {
  bottom: -10px;
  left: 50%;
  position: absolute;
  transform: rotate( -90deg );
  transform-origin: center left;
  white-space: nowrap;
}

td div {
  padding: 4px;
  position: relative;
}

td div div {
  display: none;
}

td.sameleague > div {
  background-color: rgba(255, 255, 0, 0.3);
}

td.sametermin > div {
  background-color: rgba(255, 128, 0, 0.3);
}

td.sameclub > div {
  background-color: rgba(255, 0, 0, 0.3);
}

td.wish div div.wish {
  position: absolute;
  left: 10px;
  top: 10px;
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: rgba(0, 255, 0, 0.5);
}

td.wish.sameclub div div.wish {
  display: none;
}

td.ok div div.ok {
  font-weight: bold;
  position: absolute;
  left: 2px;
  top: 0px;
  display: block;
}

td.neutral div div.neutral {
  font-weight: bold;
  position: absolute;
  left: 5px;
  top: 0px;
  display: block;
}

</style>
